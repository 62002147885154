import React from "react"

// assets
import BeeyanihanKV1 from "../../assets/images/about/beeyanihan-kv-1.svg"
import BeeyanihanKV2 from "../../assets/images/about/beeyanihan-kv-2.svg"
import BeeyanihanKV1M from "../../assets/images/about/beeyanihan-kv-1-m.svg"
import BeeyanihanKV2M from "../../assets/images/about/beeyanihan-kv-2-m.svg"

const aboutBeeyanihanSection = () => {
  return (
    <div id="beeyanihan-section">
      <div className="kv-grid-1">
        <div className="text">
          <h2>Be part of the “Beeyanihan”</h2>
          <p>
            Igniting the Filipino Bayanihan Spirit online through a
            multi-purpose community app. Servbees aims to go beyond easing up
            day-to-day transactions and become a community that sparks holistic
            growth for all.
            <br />
            <br />
            <strong>Be there for each other no matter what.</strong>
            <br />
            <br />
            In this community, we support each other in our best or trying
            times.
          </p>

          <div className="number-container">
            <div className="number">
              <p>1</p>
            </div>

            <p>
              We work towards uplifting the Philippine business scene;
              prioritizing local products, sellers, and service providers.
            </p>
          </div>

          <div className="number-container">
            <div className="number">
              <p>2</p>
            </div>

            <p>
              We share tips and tools to help each one become better in their
              hustle.
            </p>
          </div>

          <div className="number-container">
            <div className="number">
              <p>3</p>
            </div>

            <p>
              We provide a safe space, with zero tolerance for pranks and
              scams—encouraging everyone to be verified members.
            </p>
          </div>
        </div>

        <div className="image">
          <img src={BeeyanihanKV1} alt="" />
        </div>
      </div>

      <div className="kv-grid-2">
        <div className="image">
          <img src={BeeyanihanKV2} alt="" />
        </div>

        <div className="text">
          <h2>
            Bee the spark that will start the Beeyanihan in your community.
          </h2>
          <p>
            True Beeyanihan is all about building and supporting each other in a
            community with less barriers, and more opportunities for all.{" "}
          </p>
        </div>
      </div>

      <div className="mobile-content">
        <div className="kv1">
          <img src={BeeyanihanKV1M} alt="" />
        </div>

        <div className="text">
          <h2>
            Be part of the
            <br />
            “Beeyanihan”
          </h2>
          <p>
            Igniting the Filipino Bayanihan Spirit online through a
            multi-purpose community app. Servbees aims to go beyond easing up
            day-to-day transactions and become a community that sparks holistic
            growth for all.
            <br />
            <br />
          </p>

          <p className="bold">Be there for each other no matter what.</p>

          <p>
            In this community, we support each other in our best or trying
            times.
          </p>

          <div className="number-container">
            <div className="number">
              <p>1</p>
            </div>

            <p>
              We work towards uplifting the Philippine business scene;
              prioritizing local products, sellers, and service providers.
            </p>
          </div>

          <div className="number-container">
            <div className="number">
              <p>2</p>
            </div>

            <p>
              We share tips and tools to help each one become better in their
              hustle.
            </p>
          </div>

          <div className="number-container">
            <div className="number">
              <p>3</p>
            </div>

            <p>
              We provide a safe space, with zero tolerance for pranks and
              scams—encouraging everyone to be verified members.
            </p>
          </div>
        </div>

        <div className="text">
          <h2>
            Bee the spark that will start the Beeyanihan in your community.
          </h2>
          <p>
            True Beeyanihan is all about building and supporting each other in a
            community with less barriers, and more opportunities for all.{" "}
          </p>
        </div>

        <div className="kv2">
          <img src={BeeyanihanKV2M} alt="" />
        </div>
      </div>
    </div>
  )
}

export default aboutBeeyanihanSection
