import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Tab, Tabs, Box } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"

// assets
import SSLogo from "../../assets/images/about/logo-ss.svg"

import glyph1 from "../../assets/images/about/glyph-secured.png"
import glyph3 from "../../assets/images/about/glyph-legit.png"
import glyph2 from "../../assets/images/about/glyph-verified.png"
import glyph4 from "../../assets/images/about/glyph-3days.png"
import paymongo from "../../assets/images/about/logo-paymongo.svg"
// import paypal from "../../assets/images/about/logo-paypal.svg"

import po1 from "../../assets/images/payment/gcash.svg"
import po2 from "../../assets/images/payment/grabpay.svg"
import po3 from "../../assets/images/payment/visa.svg"
import po4 from "../../assets/images/payment/mastercard.svg"
// import po5 from "../../assets/images/payment/amex.svg"
// import po6 from "../../assets/images/payment/unionpay.svg"
// import po7 from "../../assets/images/payment/logo-6.svg"

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: "#FFD400",
    width: 2,
  },
})(Tabs)

const CustomTab = withStyles({
  root: {
    textTransform: "none",
    fontSize: 16,
    lineHeight: "24px",
    paddingRight: 62,
    fontFamily: "Rounded MPlus Regular",
  },
  wrapper: {
    alignItems: "flex-end",
    textAlign: "right",
  },
})(Tab)

const CustomBox = withStyles({
  root: {
    borderLeft: `2px solid #3466E9`,
    height: "auto",
    marginLeft: -2,
    width: "100%",
    maxWidth: 1000,
    paddingLeft: 62,
  },
})(Box)

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <CustomBox p={3}>{children}</CustomBox>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const a11yProps = index => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    height: 358,
    maxWidth: 1328,
    margin: "0 auto",
    padding: "16px 0",
    color: "#fff",
  },
  tabs: {
    color: "#fff",
  },
}))

const AboutServbeesShieldSection = () => {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const STCallback = entries => {
    const [entry] = entries
    setValue(entry.isIntersecting ? 1 : 0)
    console.log(entry.isIntersecting)
  }

  const options = useMemo(() => {
    return {
      threshold: 0,
    }
  }, [])

  useEffect(() => {
    const STContainer = document.querySelector("#switch-trigger")

    const STObserver = new IntersectionObserver(STCallback, options)

    if (STContainer) STObserver.observe(STContainer)
  }, [options])

  return (
    <div id="servbees-shield-section">
      <div className="title-container">
        <img src={SSLogo} alt="" />
        <h2>
          Servbees Shield
        </h2>
      </div>
      <div id="tab-container" className={classes.root}>
        <CustomTabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <CustomTab label={`Sellers & Service Providers`} {...a11yProps(0)} />
          <CustomTab label="For Customers" {...a11yProps(1)} />
        </CustomTabs>
        <TabPanel value={value} index={0}>
          <div className="tab-grid">
            <div className="grid-item">
              <img src={glyph1} className="glyph-icon" alt="" />
              <h4 className="heading">Secure Payout Channels</h4>
              <p className="text">
                Earnings are consolidated weekly, kept safely, and easily
                accessible through our trusted payment partners.
              </p>

              <div className="powered-by-container">
                <p className="text">POWERED BY</p>
                <img src={paymongo} style={{ marginRight: 4 }} alt="" />
                {/* <img src={paypal} alt="" /> */}
              </div>

              <div className="payment-options-container">
                <img src={po1} className="icon" alt="" />
                <img src={po2} className="icon" alt="" />
                <img src={po3} className="icon" alt="" />
                <img src={po4} className="icon" alt="" />
                {/* <img src={po5} className="icon" alt="" />
                <img src={po6} className="icon" alt="" />
                <img src={po7} className="icon" alt="" /> */}
              </div>
            </div>
            <div className="grid-item">
              <img src={glyph2} className="glyph-icon" alt="" />
              <h4 className="heading">Legit Customers</h4>
              <p className="text">
                Only customers who have completed the necessary documents and
                vetted data will get a bee-rified badge. Check the profile and
                badge for safer transactions.
              </p>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="tab-grid">
            <div className="grid-item">
              <img src={glyph3} className="glyph-icon" alt="" />
              <h4 className="heading">Verification Check</h4>
              <p className="text">
                Only sellers and service providers who have completed the
                necessary documents and vetted data will get a bee-rified badge.
                This is a quick guide on what to look for and who to connect
                with for safer transactions.
              </p>
            </div>
            <div className="grid-item">
              <img src={glyph4} className="glyph-icon" alt="" />
              <h4 className="heading">3-Day Confirmation Period</h4>
              <p className="text">
                Customers are given time to thoroughly check orders for any
                damage or missing parts, to raise possible concerns, and to file
                for return, exchange, or refund. Payments are secure and will
                only be released once the items have been confirmed to be
                received in good condition and services have been completed.
              </p>
            </div>
          </div>
        </TabPanel>
      </div>

      <div className="mobile-content">
        <div className="category-group">
          <div className="category-container">
            <h3 className="category">For Sellers &amp; Service Providers</h3>
          </div>
          <div className="block-item">
            <img src={glyph1} className="glyph-icon" alt="" />
            <h4 className="heading">Secure Payout Channels</h4>
            <p className="text">
              Earnings are consolidated weekly, kept safely, and easily
              accessible through our trusted payment partners.
            </p>

            <div className="powered-by-container">
              <p className="text">POWERED BY</p>
              <img src={paymongo} style={{ marginRight: 4 }} alt="" />
              {/* <img src={paypal} alt="" /> */}
            </div>

            <div className="payment-options-container">
              <div className="payment-options-wrapper">
                <img src={po1} className="icon" alt="" />
                <img src={po2} className="icon" alt="" />
                <img src={po3} className="icon" alt="" />
                <img src={po4} className="icon" alt="" />
                {/* <img src={po5} className="icon" alt="" />
                <img src={po6} className="icon" alt="" />
                <img src={po7} className="icon" alt="" /> */}
              </div>
            </div>
          </div>

          <div className="block-item">
            <img src={glyph2} className="glyph-icon" alt="" />
            <h4 className="heading">Legit Customers</h4>
            <p className="text">
              Only customers who have completed the necessary documents and
              vetted data will get a bee-rified badge. Check the profile and
              badge for safer transactions.
            </p>
          </div>
        </div>

        <div className="category-group">
          <div className="category-container">
            <h3 className="category">For Customers</h3>
          </div>
          <div className="block-item">
            <img src={glyph3} className="glyph-icon" alt="" />
            <h4 className="heading">Verification Check</h4>
            <p className="text">
              Only sellers and service providers who have completed the
              necessary documents and vetted data will get a bee-rified badge.
              This is a quick guide on what to look for and who to connect with
              for safer transactions.
            </p>
          </div>
          <div className="block-item">
            <img src={glyph4} className="glyph-icon" alt="" />
            <h4 className="heading">3-Day Confirmation Period</h4>
            <p className="text">
              Customers are given time to thoroughly check orders for any damage
              or missing parts, to raise possible concerns, and to file for
              return, exchange, or refund. Payments are secure and will only be
              released once the items have been confirmed to be received in good
              condition and services have been completed.
            </p>
          </div>
        </div>
      </div>

      <div id="switch-trigger" className="switch-trigger"></div>
    </div>
  )
}

export default AboutServbeesShieldSection
