import React from "react"
import { Link } from "gatsby"

const sectionCta = () => {
  return (
    <div className="section-cta">
      <div className="container">
        <p className="text">Can't find what you're looking for?</p>
        <Link to="/contact" className="btn btn-blue">
          Contact Us
        </Link>
      </div>
    </div>
  )
}

export default sectionCta
