import React from "react"

// assets
import clouds from "../../assets/images/about/vision-clouds.svg"
import hill from "../../assets/images/about/mission-hill.svg"

const aboutMVSection = () => {
  return (
    <div id="mv-card-section">
      <div className="vision-container">
        <img src={clouds} className="clouds" alt="" />
        <h2>Vision</h2>
        <p>
          We serve to fuel a true sharing and gig economy: one with less
          barriers and more leads.
          <br />
          <br />
          Servbees is built to support and grow alongside dreamers and doers of
          all stripes, to uplift local communities, and to spark opportunities
          for all.
        </p>
      </div>
      <div className="mission-container">
        <img src={hill} className="hill" alt="" />
        <h2>Mission</h2>
        <p>
          We are empowering individuals and communities to create opportunities
          through disruptive technology.
          <br />
          <br />
          Servbees enables them to find their market, book rewarding gigs, sell
          their goods, work flexibly, and earn — all on their own terms, all
          through the app.
        </p>
      </div>
    </div>
  )
}

export default aboutMVSection
