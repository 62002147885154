import React from "react"

// assets
import iconSell from "../../assets/images/about/icon-sell.svg"
import iconOffer from "../../assets/images/about/icon-offer.svg"
import iconFind from "../../assets/images/about/icon-find.svg"
import Glyph1 from "../../assets/images/about/feature-glyph-1.svg"
import Glyph2 from "../../assets/images/about/feature-glyph-2.svg"
import Glyph3 from "../../assets/images/about/feature-glyph-3.svg"
import Phone from "../../assets/images/about/servbees-phone-v2.png"

const aboutFeaturesSection = () => {
  return (
    <div id="features-section">
      <div className="main-features-container">
        <h2>
          What can you do on Servbees?
        </h2>

        <div className="icon-text-container">
          <img src={iconOffer} alt="" />

          <div className="text-container">
            <h3 className="heading">Offer Services</h3>
            <p className="text">
              Get your hustle on and earn from your skills, talents, and
              creative side stints.
            </p>
          </div>
        </div>

        <div className="icon-text-container">
          <img src={iconSell} alt="" />

          <div className="text-container">
            <h3 className="heading">Sell Items</h3>
            <p className="text">
              Capture a new market for your new products, pre-loved goods, and
              grow your business.
            </p>
          </div>
        </div>

        <div className="icon-text-container">
          <img src={iconFind} alt="" />

          <div className="text-container">
            <h3 className="heading">Find Deals</h3>
            <p className="text">
              Post what you’re looking for and get options hassle-free.
            </p>
          </div>
        </div>
      </div>

      <div className="phone-container">
        <img src={Phone} alt="" />
      </div>

      <div className="coming-soon-container">
        <div className="title-container">
          <p className="coming-soon">COMING SOON</p>
          <h3 className="title">Share-a-Sale</h3>
          <p className="text">
            A unique Beeyanihan app feature that allows you to earn by helping
            other Buzzybees earn! It's easy!{" "}
            <span className="break">
              <br />
            </span>
            Be a co-seller, today!
          </p>
        </div>

        <div className="glyph-grid">
          <div className="glyph-item">
            <img src={Glyph1} className="glyph-image" alt="" />
            <h3 className="glyph-text">
              Watch out for the
              <br />
              Share-a-Sale Badge
            </h3>
          </div>
          <div className="glyph-item">
            <img src={Glyph2} className="glyph-image" alt="" />
            <p className="glyph-text">
              Click and share your
              <br />
              unique link
            </p>
          </div>
          <div className="glyph-item">
            <img src={Glyph3} className="glyph-image" alt="" />
            <p className="glyph-text">
              Get commission for every
              <br />
              successful transaction
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default aboutFeaturesSection
