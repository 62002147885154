import React from "react"

// assets
import AboutKV from "../../assets/images/about/about-kv.svg"
import AboutKVMobile from "../../assets/images/about/about-kv-mobile.svg"
import IntroIcon1 from "../../assets/images/about/intro-icon-1.svg"
import IntroIcon2 from "../../assets/images/about/intro-icon-2.svg"
import IntroIcon3 from "../../assets/images/about/intro-icon-3.svg"
import IntroIcon1M from "../../assets/images/about/intro-icon-1-m.svg"
import IntroIcon2M from "../../assets/images/about/intro-icon-2-m.svg"
import IntroIcon3M from "../../assets/images/about/intro-icon-3-m.svg"

const aboutIntroSection = () => {
  return (
    <div id="about-intro-section">
      <div className="bg-gradient" />

      <h2>What's the Buzz About?</h2>
      <p className="intro-text">
        Servbees is a new app that supports and connects sellers, service
        providers, freelancers, entrepreneurs, and businesses with customers
        within the community.
      </p>

      <div className="img-wrapper">
        <img src={AboutKV} className="about-img" alt="About Servbees" />
        <img
          src={AboutKVMobile}
          className="about-img-mobile"
          alt="About Servbees"
        />
      </div>

      <h2>Buzz-worthy Service, All for You</h2>
      <p className="intro-text">
        Service is at the core of our mission (as it is in our name!). Servbees
        is here to help you find what you need nearby and provide you with tools
        to become the best in your biz.
      </p>

      <div className="icon-text-container">
        <img
          src={IntroIcon1}
          className="intro-icon"
          alt="Part Mobile Marketplace, Part Gig Hub"
        />
        <img
          src={IntroIcon1M}
          className="intro-icon-mobile"
          alt="Part Mobile Marketplace, Part Gig Hub"
        />

        <div className="text-container">
          <h3 className="heading">Part Mobile Marketplace, Part Gig Hub</h3>
          <p className="text">
            With selling and hustling going full-on digital, Servbees is the
            right platform to cater to this. This Pagkakakita-App offers
            flexible yet rewarding business opportunities, seamless
            transactions, and convenient solutions—not only for customers but
            for the busy hustlers, too!
          </p>
        </div>
      </div>

      <div className="icon-text-container">
        <img
          src={IntroIcon2}
          className="intro-icon"
          alt="Proudly hatched in the Philippines and Singapore, 2020"
        />
        <img
          src={IntroIcon2M}
          className="intro-icon-mobile"
          alt="Proudly hatched in the Philippines and Singapore, 2020"
        />

        <div className="text-container">
          <h3 className="heading">
            Proudly hatched in the Philippines and Singapore, 2020
          </h3>
          <p className="text">
            Right in the middle of a pandemic, Servbees began its mission to
            serve, connect, and support Buzybees of every stripe.
          </p>
        </div>
      </div>

      <div className="icon-text-container">
        <img
          src={IntroIcon3}
          className="intro-icon"
          alt="Servbees is 100% Pinoy made!"
        />
        <img
          src={IntroIcon3M}
          className="intro-icon-mobile"
          alt="Servbees is 100% Pinoy made!"
        />

        <div className="text-container">
          <h3 className="heading">Servbees is 100% Pinoy made!</h3>
          <p className="text">
            Servbees is proudly made in the Philippines, by Filipinos, for
            Filipinos. It’s all about Beeyanihan here!
          </p>
        </div>
      </div>
    </div>
  )
}

export default aboutIntroSection
