import React from "react"

// components
import Layout from "../components/layout"
import SEO from "../components/seo"
import IntroSection from "../components/sections/about-intro-section"
import MVSection from "../components/sections/about-mv-section"
import FeaturesSection from "../components/sections/about-features-section"
import BeeyanihanSection from "../components/sections/about-beeyanihan-section"
import ServbeesShieldSection from "../components/sections/about-servbees-shield-section"
import Cta from "../components/section-cta"

const About = () => {
  return (
    <Layout
      className="bg-design vector-dash blue-footer"
      style={{ paddingTop: 0, backgroundColor: "#F9F9F9" }}
    >
      <SEO
        pageTitle="About Us"
        title="About Us | Servbees"
        description="Part online marketplace, part gig hub, Servbees is your friendly neighborhood pagkakakita-app!"
      />
      <div className="about">
        <div className="container">
          <IntroSection />
        </div>

        <MVSection />

        <div className="container">
          <FeaturesSection />
        </div>

        <ServbeesShieldSection />

        <div className="container">
          <BeeyanihanSection />
        </div>

        <Cta />
      </div>
    </Layout>
  )
}

export default About
